import { graphql } from "gatsby";

import Email from "../../organisms/lead/Email";
import OnboardingLayout from "../../organisms/lead/OnboardingLayout";

const EmailPage = (): JSX.Element => (
  <OnboardingLayout>
    <Email />
  </OnboardingLayout>
);
export default EmailPage;

export const query = graphql`
  query EmailPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
