import cn from "classnames";
import { Form, FormikHelpers, FormikProps } from "formik";
import { Link, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useResetRecoilState } from "recoil";
import * as Yup from "yup";

import Button from "../../atoms/button/Button";
import Checkbox from "../../atoms/checkbox/Checkbox";
import Legal from "../../atoms/legal";
import TextFormControl from "../../atoms/text-form-control/TextFormControl";
import { usePhoneNumber, UsePhoneNumberFormValues } from "../../core/domain/hooks/usePhoneNumber";
import useOnce from "../../core/utils/hooks/useOnce";
import { sanitizePhone } from "../../core/utils/sanitizers";
import { validateEmail } from "../../core/utils/validators";
import { useLead, useSetLead } from "../../features/leads-funnel/application/lead-use-cases";
import { Lead, LeadDTO } from "../../features/leads-funnel/domain/lead";
import PageStepForm from "../../organisms/page-step-form/PageStepForm";
import { Locale } from "../../settings/countries";
import { Events, track, trackChoice } from "../../utils/analytics";
import {
  handleCreateResultErrors,
  handleLeadErrors,
} from "../../utils/error-utils/catch-error-handlers";
import { experiments, isExperimentDefaultVariant } from "../../utils/experiments";
import {
  getCountryByLocale,
  getPhoneNumberPrefix,
  shouldShowAlternativeOnboardingEmailTitle,
  shouldShowPhoneNumberInPreQuotes,
  shouldShowWhatsAppConfirmationCheckbox,
} from "../../utils/locale-configuration-utils";
import { rollbar } from "../../utils/rollbar";
import { getAllUtmsPrefixed } from "../../utils/utm";
import { currentResultUuidState, resultState } from "../result/state/atoms";
import { useCreateResult } from "../result/use-cases/result-use-cases";
import * as styles from "./Email.module.scss";

interface EmailFormValues extends UsePhoneNumberFormValues {
  email: string;
  whatsAppConfirmation?: boolean;
}

interface Image {
  default: string;
}

const emailSVG: Image = require("../../features/leads-funnel/ui/images/envelope.svg") as Image;

const emailSVGSrc: string = emailSVG.default;

const Email = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const lead: Lead = useLead();
  const setLead = useSetLead();
  const createResult = useCreateResult();
  const phoneNumberPrefix = getPhoneNumberPrefix(lead.countryIso);
  const shouldShowPhoneNumber = shouldShowPhoneNumberInPreQuotes(lead.countryIso);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [leadToCreateResultIsInvalid, setLeadToCreateResultIsInvalid] = useState(false);
  const seePriceButtonRef = useRef<HTMLDivElement>(null);
  const resetUuid = useResetRecoilState(currentResultUuidState);
  const resetResult = useResetRecoilState(resultState);
  const pageTitle =
    shouldShowAlternativeOnboardingEmailTitle(getCountryByLocale(i18n.language as Locale)) &&
    isExperimentDefaultVariant(experiments.alternativeOnboardingEmailTitle)
      ? t("onboarding.email.alternative_title")
      : t("onboarding.email.title", { petName: lead.petName });

  const {
    initialValues: phoneNumberInitialValues,
    validationSchema: phoneNumberValidationSchema,
    phoneNumberLength: { min: phoneMinLength, max: phoneMaxLength },
  } = usePhoneNumber({
    defaultCountryCode: phoneNumberPrefix,
    country: lead.countryIso,
    isWhatsAppNumber: true,
    isPhoneNumberRequired: false,
  });

  useEffect(() => {
    if (resetUuid && resetResult) {
      resetUuid();
      resetResult();
    }
  }, [resetUuid, resetResult]);

  useEffect(() => {
    if (lead && lead.email && validateEmail(lead.email)) {
      setIsEmailValid(true);
    }
  }, [lead, lead.email]);

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: boolean) => void
  ): void => {
    const properties = {
      question: "WhatsApp number confirmation",
    };

    trackChoice(e, properties);
    setFieldValue("whatsAppConfirmation", e.target.checked);
  };

  const handleStartTyping = useOnce(() => {
    track(Events.START_TYPING_ON_FIELD, {
      eventSender: "Email page form - Phone number",
    });
  });

  const EmailFormData = {
    initialValues: {
      email: lead.email,
      countryCode: phoneNumberInitialValues.countryCode,
      phone: phoneNumberInitialValues.phone,
      whatsAppConfirmation: true,
    },
    validationSchema: Yup.object({
      email: Yup.mixed()
        .required(t("common.validation.required"))
        .test("validate-email", t("common.validation.valid_email"), (value: string): boolean => {
          if (!value) {
            return false;
          }

          return validateEmail(value);
        }),
      whatsAppConfirmation: Yup.boolean(),
    }).concat(phoneNumberValidationSchema),
    handleSubmit: (
      values: EmailFormValues,
      { setSubmitting }: FormikHelpers<EmailFormValues>
    ): void => {
      if (!values.email) {
        return;
      }

      setSubmitting(true);

      if (shouldShowPhoneNumber && values.countryCode && values.phone) {
        const sanitizedPhone = sanitizePhone(values.phone, lead.countryIso);
        const fullPhoneNumber = `+${values.countryCode}${sanitizedPhone}`;

        track(Events.PHONE_SUBMITTED, {
          eventSender: "Email page form",
          preQuotesPhoneNumber: fullPhoneNumber,
          petName: lead.petName,
          whatsAppConfirmation: values.whatsAppConfirmation,
        });
      }

      const _values = {
        ...lead,
        email: values.email,
      } as LeadDTO;

      setLead({
        ..._values,
        ...getAllUtmsPrefixed(),
        countryIso: getCountryByLocale(i18n.language as Locale),
      })
        .then((updatedLead: Lead | undefined): void => {
          if (!updatedLead) {
            setSubmitting(false);

            return;
          }

          try {
            updatedLead.ensureLeadToCreateResultIsValid();
          } catch (err) {
            handleLeadErrors(err, "onboarding/email");
            setHasSubmitError(true);
            setLeadToCreateResultIsInvalid(true);
            setSubmitting(false);

            return;
          }

          createResult(updatedLead.toSnakeCase())
            .then((result) => {
              if (!result) {
                setSubmitting(false);

                return;
              }

              track(Events.FORM_ANSWERED, {
                question: "Email",
                answer: values.email,
              });

              void navigate(`/results/${result.uuid}/quoting/`);
            })
            .catch((err: Error) => {
              handleCreateResultErrors(err, "onboarding/email");
              setHasSubmitError(true);
              setSubmitting(false);
            });
        })
        .catch((): void => {
          rollbar.warn("There was an error setting the lead.");
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<EmailFormValues>) => {
      const { isSubmitting, isValid, handleChange, setFieldValue, values } = props;

      return (
        <Form className={cn(styles.emailForm)}>
          <TextFormControl
            type={"email"}
            isFocusedOnRender
            name="email"
            label={t("onboarding.email.field.title")}
            onChange={(e) => {
              const value = e.target.value;

              if (validateEmail(value)) {
                setIsEmailValid(true);
                track(Events.ELEMENT_SHOWN, { element: "Email CTA" });
              }

              handleChange(e);
            }}
          />

          {isEmailValid && (
            <div className={cn(styles.seePriceWrapper)} ref={seePriceButtonRef}>
              {shouldShowPhoneNumber && (
                <div className={styles.phoneNumberWrapper}>
                  <svg className={cn("icon", `icon--whatsapp`, styles.icon)} role="img">
                    <use href={`#icon-whatsapp`}></use>
                  </svg>
                  <p className={styles.phoneNumberDescription}>
                    {t("onboarding.email.phone_number.description")}
                  </p>
                  <div className={styles.phoneNumberFieldsWrapper}>
                    <TextFormControl
                      adoptionClassName={styles.phoneNumberPrefix}
                      name="countryCode"
                      label={t("common.phone_number.country_code.field.title")}
                      type="tel"
                      maxLength={4}
                      onChange={(e) =>
                        setFieldValue("countryCode", e.target.value.replace(/\D/g, ""))
                      }
                    />
                    <TextFormControl
                      name="phone"
                      label={t("onboarding.email.whatsapp.phone_number.field.title")}
                      type="tel"
                      minLength={phoneMinLength}
                      maxLength={phoneMaxLength}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("phone", value.replace(/\D/g, ""));
                        if (value.length > 0) {
                          handleStartTyping();
                        }
                      }}
                    />
                  </div>
                  {shouldShowWhatsAppConfirmationCheckbox(lead.countryIso) && (
                    <Checkbox
                      name="whatsAppConfirmation"
                      id="whatsAppConfirmation"
                      isChecked={values.whatsAppConfirmation || false}
                      onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                    >
                      {t("onboarding.email.whatsapp.confirmation_checkbox")}
                    </Checkbox>
                  )}
                </div>
              )}
              <Button type="submit" disabled={isSubmitting || !isValid} isLoading={isSubmitting}>
                {t("common.cta.see_price")}
              </Button>
              {hasSubmitError && (
                <p className={cn(styles.errorMessage)}>
                  {leadToCreateResultIsInvalid ? (
                    <>
                      {t("common.validation.lead_invalid.error_message")}{" "}
                      <Link to={`/${i18n.language}/onboarding/species/`}>
                        {t("common.validation.lead_invalid.error_message.link")}
                      </Link>
                    </>
                  ) : (
                    t("common.error_message")
                  )}
                </p>
              )}
              <div className={styles.legal}>
                <Legal />
              </div>
            </div>
          )}
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={pageTitle}
      subtitle={t("onboarding.email.subtitle")}
      image={emailSVGSrc}
      formData={EmailFormData}
    />
  );
};
export default Email;
