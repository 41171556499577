import { useTranslation } from "gatsby-plugin-react-i18next";

const Legal = () => {
  const { t } = useTranslation();
  const urlBase = process.env.GATSBY_URL_BASE;
  const termsUrl = `${urlBase}/${t("landing.modal.terms-and-conditions.slug")}`;
  const privacyUrl = `${urlBase}/${t("landing.modal.privacy-policy.slug")}`;

  return (
    <p>
      <small
        dangerouslySetInnerHTML={{
          __html: t("landing.modal.terms", {
            brandName: "Barkibu",
            termsUrl,
            privacyUrl,
          }),
        }}
      />
    </p>
  );
};

export default Legal;
