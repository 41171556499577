import cn from "classnames";
import { ChangeEvent, ReactNode } from "react";

import * as styles from "./Checkbox.module.scss";

interface CheckboxProps {
  id: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
  isChecked: boolean;
  errorMessage?: string;
  adoptionClassName?: string;
  variantName?: string;
}
const Checkbox = ({
  id,
  name,
  onChange,
  children,
  isChecked,
  errorMessage = "",
  adoptionClassName,
  variantName,
  ...props
}: CheckboxProps): JSX.Element => {
  return (
    <div
      className={cn(styles.checkboxField, adoptionClassName, {
        [styles[variantName as string]]: variantName,
      })}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={onChange}
        checked={isChecked}
        {...props}
      />
      <label htmlFor={id}>{children}</label>
      {errorMessage && (
        <p
          className={cn(styles.formFieldFeedback, styles.formFieldFeedbackAlert)}
          role="alert"
          tabIndex={-1}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default Checkbox;
